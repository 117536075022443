
import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import { Container } from 'react-bootstrap';
import Header from './components/Header';
import Home from './components/Home';
import Help from './components/Help/index.js';
import Resources from './components/Resources';
// import FAQ from './components/FAQ';
import Footer from './components/Footer';


function App() {
  return (
    <>
    <BrowserRouter>
        <Header
          // setCurrentCat={setCurrentCat}
          // currentCategory={currentCategory} 
        />
      <Container className='height-100'>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/how-to-help" element={<Help />} />
          <Route exact path="/resources" element={<Resources />} />
          {/* <Route exact path="/faq" element={<FAQ />} /> */}
        </Routes>
      </Container>
      <Footer />
    </BrowserRouter>
    </>
  );
};

export default App;
