import React from 'react';
import { Image } from 'react-bootstrap';
import img631 from '../../assets/Code_631.png'

const Help = () => {
    return(
        <>
            <div className="row flex-lg-row-reverse align-items-center g-5 py-5">
                <h1 className="display-5 fw-bold lh-1">Helping Is Easy</h1>
                <p className="lead mt-2">Follow the steps below to help improve Indiana's Infrastructure <i><u>today!</u></i></p>
                <hr />
                <h3 className='margin-0-helper'><span className='stepper'>Step 1</span> | <i>Confirm your local Regional Development Authority exists.</i></h3>
                <div className="lead m-0">
                    <ul>
                        <li className='mt-3 mb-4'>You can begin by checking <a href="https://www.iedc.in.gov/program/indiana-readi/regions" target="_blank" rel="noreferrer">this list</a> of Participating Regions in the Indiana Economic Development Corporation's (IEDC) READI program. If a region has "RDA" in their name,
                            they are likely a Regional Development Authority.
                        </li>
                        <li className='mb-4'>All Regional Development Authorities are also audited by the Indiana State Board of Accounts, so you can check with them to verify an RDA exists.
                            <br/><a href="https://www.in.gov/sboa/about-us/contact-us/" target="_blank" rel="noreferrer"> SBOA | Contact Us</a>
                        </li>
                    </ul>
                </div>
                <hr />
                <h3 className='margin-0-helper'><span className='stepper'>Step 2</span> | <i>Mail a check to the Indiana Finance Authority.</i></h3>
                <div className="lead m-0">
                    <ul>
                        <li className='mt-3 mb-4'>How much you contribute is up to you. 
                            <ul className='mb-3'>
                                <li>For context, the <a href="https://sealmaster.net/faq/much-cost-fix-pothole/#:~:text=Typical%20cost%20to%20repair%20potholes%20is%20approximately%20%2435%20to%20%2450%20per%20pothole." target="_blank" rel="noreferrer">average cost to repair a pothole</a> is $35 – $50 per pothole for materials only. Include the cost of labor and that cost rises to $135 – $200+ per pothole. One less pothole is still one less pothole.
                                </li>
                            </ul>
                        </li>
                        <li className='mt-3 mb-4'>Mail to: 
                        <br/>
                        <i className="addie-info">Indiana Finance Authority</i>
                        <br/>
                        <i className="addie-info">Re: Regional Development Authority Infrastructure Fund</i>
                        <br/>
                        <i className="addie-info">One North Capital, STE 900</i>
                        <br/>
                        <i className="addie-info">Indianapolis, IN 46204</i>
                        </li>
                        <li className='="mt-3 mb-4'>Check payable to:
                        <br />
                        <i className="addie-info">Indiana Finance Authority</i>
                        </li>
                        <li className='="mt-3 mb-4'>It would also be prudent to enclose a cover letter indicating which Regional Development Authority (RDA) the check is intended for. You could indicate your intention by writing 
                         <i> FBO XYZ Regional Development Authority</i> in the check memo line as well. 
                         <ul>
                             <li>Replace the "XYZ" with the name of your RDA. For example, the RDA for Indianapolis is the <a href="https://www.indympo.org/maps-resources/rda" target="_blank" rel="noreferrer"><i>Central IN Regional Development Authority.</i></a></li>
                         </ul>
                         </li> 
                        <li className='="mt-3 mb-4'>The Indiana Finance Authority will then establish an account for this RDA or add your funds to an account already established for the RDA. This is outlined in the Indiana Code: <a href="http://iga.in.gov/legislative/laws/2021/ic/titles/036/#36-9-43-9" target={"_blank"} rel="noreferrer">IC 36-9-43-9.</a> </li>
                    </ul>
                </div>
                <hr />
                <h3 className='margin-0-helper'><span className='stepper'>Step 3</span> | <i>Make your Regional Development Authority aware funds have been contributed for their use.</i></h3>
                <div className="lead m-0">
                    <ul className='mb-3'>
                        <li className='mt-3 mb-4'>Once funds are deposited to the Infrastructure fund for use by the Regional Development Authority (RDA), only the designated RDA for that account may utilize the funds.</li>
                        <li className='mt-3 mb-4'>It is possible the Indiana Finance Authority does not let the RDA know funds have been deposited, so it is up to you to let them know! (It also wouldn't hurt to ask they use the funds to fix the potholes.)</li>
                        <li className='mt-3 mb-4'>You may find your specific RDA's contact information by following one or both of the links in Step 1.</li>
                    </ul>
                </div>
                <hr />
                <h3 className='margin-0-helper'><span className='stepper'>Step 4</span> | <i>Take advantage of the tax benefits of your contribution.</i></h3>
                <div className="lead m-0">
                    <ul className='mb-3'>
                        <li className='mt-3 mb-4'>Excellent job committing to improving our communities!</li>
                        <li className='mt-3 mb-4'>As mentioned in the <a href="https://www.in.gov/dor/files/reference/ib113.pdf" target={"_blank"} rel="noreferrer">2019 Information Bulletin</a>, a taxpayer may take a deduction on their taxes for the year in which they contributed to the fund.
                            <ul>
                                <li>Per the bulletin: <i>"The deduction is only available for adjusted gross income tax purposes. To claim the credit, the taxpayer must report the deduction using code 631 on the taxpayer's Indiana income tax return."</i></li>
                            </ul>
                            
                        </li>
                        <a href="https://www.in.gov/dor/files/IT-40-Instructions-Complete-Book-Annotated-09-20.pdf" target="_blank" rel="noreferrer"><Image rounded="true" src={img631} alt="Pave A Pothole Logo" className="my-auto img-fluid hero-image" /></a>
                        <li className='mt-3 mb-4'>This deduction is limited to an amount <i>"equal to the amount of the federal income tax deduction allowable to the entity under <a href="https://www.law.cornell.edu/uscode/text/26/170" target="_blank" rel="noreferrer">Section 170</a> of the Internal Revenue Code for the taxable year for the taxpayer's contribution or gift to the fund. The taxpayer is permitted
                        the deduction even if the taxpayer does not actually claim the deduction for federal tax purposes."</i></li>
                        <li className='mt-3 mb-4'>Keep as much documentation regarding your contribution as possible. The Indiana Department of Revenue may ask you to furnish details when you claim the deduction.</li>
                        <li className='mt-3 mb-4'>Your tax preparer should also be able to assist you in claiming the deduction.</li>
                        <li className='mt-3 mb-4'>Further information regarding the deduction can be found in the Indiana Code: <a href="http://iga.in.gov/legislative/laws/2021/ic/titles/006#6-3-2-26" target="_blank" rel="noreferrer">IC 6-3-2-26.</a></li>

                    </ul>
                </div>
                <hr />
                <h3 className='margin-0-helper'><span className='stepper'>Pro Tip #1</span> | <i>Share this website and information with your friends and colleagues!</i></h3>
                <div className="lead m-0">
                    <ul className='mb-3'>
                        <li className='mt-3 mb-4'>Get the word out! Together we can help rebuild Indiana's infrastructure so it continues to be a great place to live, learn, build, and grow.</li>
                    </ul>
                    <div className='container justify-content-start align-content-center' style={{display: 'flex'}}>
                        <div className='mx-2 py-2'><a href="https://twitter.com/share?ref_src=twsrc%5Etfw" className="twitter-share-button" data-size="large" data-text="Sick of playing hopscotch with potholes? Check this out!" data-hashtags="PaveAPotholeIndiana" data-related="" data-show-count="false">Tweet</a></div>
                        <div className='mx-2 social-fb'><div id="fb-root"></div><div className="fb-share-button" data-href="https://www.paveapothole.com" data-layout="button_count" data-size="large"><a target="_blank" href="https://www.paveapothole.com" className="fb-xfbml-parse-ignore" rel="noreferrer">Share</a></div></div>
                    </div>

                </div>
                <h3 className='margin-0-helper'><span className='stepper'>Pro Tip #2</span> | <i>Write to your State and Local representatives!</i></h3>
                <div className="lead m-0">
                    <ul className='mb-3'>
                        <li className='mt-3 mb-4'>Deductions are great but credits are even better... </li>
                        <li className='mt-3 mb-4'>Given the tremendous benefit Funds like these have, it would stand to reason that changing the tax benefit from a deduction to a credit would encourage more folks to contribute. Food for thought.</li>
                    </ul>
                </div>
            </div>
        </>
    );
};

export default Help;