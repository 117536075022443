import React from 'react';
import { Breadcrumb } from 'react-bootstrap';

const Resources = () => {
    return(
        <>
            <div  className="row flex-lg-row-reverse align-items-center g-5 py-5">
                <h1 className="display-5 fw-bold lh-1">Resources</h1>
                {/* Put hyperlinks to the ids below here */}
                <h5 className='mt-3'>Categories</h5>
                <div  className='margin-0-helper' style={{marginBottom: "-10px"}}>
                    <Breadcrumb>
                        <Breadcrumb.Item href="#Indianapolis">Indianapolis</Breadcrumb.Item>
                        <Breadcrumb.Item href="#Indiana-Tax">Indiana Tax</Breadcrumb.Item>
                        <Breadcrumb.Item href="#Federal-Tax">Federal Tax</Breadcrumb.Item>
                        <Breadcrumb.Item href="#Other">Other</Breadcrumb.Item>
                    </Breadcrumb>
                </div>

                <hr/>
                <div className="margin-0-helper" id="Indianapolis">
                    <h2>Indianapolis Pothole Resources</h2>
                    <div className="lead my-3">
                        <a href="http://maps.indy.gov/IndyPotholeViewer" target="_blank" rel="noreferrer">Indy Pothole Viewer</a>
                        <div >Realtime map of active and closed potholes across the Greater Indianapolis area.</div>
                    </div>
                    <div className="lead my-3">
                        <a href="https://www.in.gov/indot/contact-indot/report-a-concern2/report-a-pothole/" target="_blank" rel="noreferrer">Report A Pothole on Highways</a>
                        <div >Instructions for reporting a pothole on any highways or state roads in Indiana to the Indiana Department of Transportation.</div>
                    </div>
                    <div className="lead my-3">
                        <a href="https://www.indy.gov/activity/report-potholes" target="_blank" rel="noreferrer">Report A Pothole on Indy Roads</a>
                        <div >Instructions for reporting a pothole on any city streets and alleys to the city of Indianapolis.</div>
                    </div>
                    <div className="lead my-3">
                        <a href="https://www.indy.gov/activity/report-potholes" target="_blank" rel="noreferrer">File A Pothole Damage Claim</a>
                        <div >You will need to use this service to report a pothole, indicate you experienced property damage on the form, and <a href="https://www.indy.gov/activity/file-a-tort-claim" target="_blank" rel="noreferrer">follow the process to file a tort claim</a> with the city.</div>
                    </div>
                    <div className="lead my-3">
                        <a href="https://www.indympo.org/maps-resources/rda" target="_blank" rel="noreferrer">Central IN Regional Development Authority</a>
                        <div >The Regional Development Authority responsible for Marion, Hamilton, and Johnson counties. If you live in Indy, this is the fund for you.</div>
                    </div>
                </div>
                <hr/>
                <div  className="margin-0-helper" id="Indiana-Tax">
                    <h2>Indiana Tax Information</h2>
                    <div className="lead my-3">
                        <a href="http://iga.in.gov/legislative/laws/2021/ic/titles/036/#36-9-43-9" target="_blank" rel="noreferrer">Indiana Code: IC 36-9-43-9</a>
                        <div >Responsible for the rules and regulations for creation of the Infrastructure Funds.</div>
                    </div>
                    <div className="lead my-0">
                        <a href="http://iga.in.gov/legislative/laws/2021/ic/titles/006#6-3-2-26" target="_blank" rel="noreferrer">Indiana Code: IC 6-3-2-26</a>
                        <div >Explains the ability to deduct donations / gifts to the Infrastructure Funds created by IC 36-9-43-9.</div>
                    </div>
                    <div className="lead my-0">
                        <a href="https://www.in.gov/dor/files/IT-40-Instructions-Complete-Book-Annotated-09-20.pdf" target="_blank" rel="noreferrer">Indiana Annotated IT-40</a>
                        <div >Navigate to page 20 to find information related to Code 631 which outlines the Infrastructure Fund Gift Deduction created in IC 6-3-2-26.</div>
                    </div>
                    <div className="lead my-0">
                        <a href="https://www.in.gov/dor/files/reference/ib113.pdf" target="_blank" rel="noreferrer">INDOR: Information Bulletin #113</a>
                        <div >The Information Bulletin published by the Indiana Department of Revenue to provide assistance to the public related to IC 36-9-43-9 and IC 6-3-2-26.</div>
                    </div>
                </div>
                <hr/>
                <div  className="margin-0-helper" id="Federal-Tax">
                    <h2>Federal Tax Information</h2>
                    <div className="lead my-3">
                        <a href="https://www.law.cornell.edu/uscode/text/26/170" target="_blank" rel="noreferrer">U.S. Code: Section 170</a>
                        <div >Explains charitable deductions available at the Federal level. This information is used to arrive at the available deduction for your contribution to an Infrastructure Fund.</div>
                    </div>
                </div>
                <hr/>
                <div  className="margin-0-helper" id="Other">
                    <h2>Other Resources</h2>
                    <div className="lead my-3">
                        <a href="https://www.in.gov/sboa/" target="_blank" rel="noreferrer">Indiana State Board of Accounts</a>
                        <div >Responsible for auditing all Indiana Regional Development Authorities.</div>
                    </div>
                    <div className="lead my-3">
                        <a href="https://www.in.gov/ifa/" target="_blank" rel="noreferrer">Indiana Finance Authority</a>
                        <div >Responsible for safekeeping of funds donated to a specific Regional Development Authority Infrastructure Fund.</div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Resources;