import React from 'react';
import potholeImage from '../../assets/pothole-mk.jpg';
import { Image, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const Home = () => {
    return(

        <>
            <div className="row flex-lg-row-reverse align-items-center g-5 py-5">
                <div className="col-sm-12 col-lg-6">
                    {/* <img src="800px-Pothole.jpg" className="d-block mx-lg-auto img-fluid" alt="Bootstrap Themes" loading="lazy"> */}
                    <Image rounded="true" src={potholeImage} alt="Pave A Pothole Logo" className="d-block mx-lg-auto img-fluid hero-image" />
                </div>
                <div className="col-lg-6">
                    <h1 className="display-5 fw-bold lh-1 mb-3">Look Familiar?</h1>
                    <p className="lead">It's a common sight for those of us navigating Central Indiana roadways. Now, this image is fairly tame as Indiana Potholes&#8482; go; yet odds are, you, a loved one, your next door neighbor, or even your four-legged companion has 
                        been affected by potholes just like these.</p> 
                    <p className="lead">All run-ins with potholes are unfortunate, but some result in expensive damage to vehicles, lost wages, and frustration. </p>
                    <p className="lead">OK, so what? This isn't exactly news to you. Every year, the IndyStar writes about how awful Indiana roads are and how little funding the municpalities have for fixing them. Well, that ends today.</p>
                    <p className="lead"><span style={{fontWeight: 700, fontSize: '24px' }}><i><u>YOU</u></i> CAN HELP.</span> </p>
                    <p className="lead">In 2019, the Indiana Department of Revenue released an <a href="https://www.in.gov/dor/files/reference/ib113.pdf" target={"_blank"} rel="noreferrer">Information Bulletin</a> informing the public
                    about a tax deduction for contributing to Regional Development Authority Infrastructure Fund. Yes, you can have your cake (fixed potholes) and eat it too (less taxes).</p>
                    <div className="d-grid gap-2 d-md-flex justify-content-md-start btn-container">
                        <Link to={"/how-to-help"}><Button variant="primary" size="lg" className="px-4 me-md-2 btn-pothole">Learn More</Button></Link>
                    </div>
                    <p className="lead mt-3"><i>P.S. This opportunity is available for all Hoosiers; not just Indy residents.</i></p>
                </div>
            </div>
        </>
    );
};

export default Home;