
import React from 'react';
import logo from '../../assets/pave-a-pothole-logo.png';
import { Container, Navbar, Nav, Image} from 'react-bootstrap';
import { Link } from 'react-router-dom';

const NavBar = () => {

    const categoryLinks = [{a: "", b: "Home"},{a:"how-to-help", b:"How To Help"},{a:"resources", b:"Resources"}];
    // ,{a: "faq", b:"FAQ"}]

    return (
        <Navbar collapseOnSelect expand="lg" className="bg-nav navbar-dark">
            <Container>
                <Navbar.Brand>
                    <Link to={"/"} className="bg-nav-text nav-link nav navbar-brand logo">
                        <Image src={logo} alt="Pave A Pothole Logo" height="183.5" width="auto" className="d-inline-block align-text-top" />
                    </Link>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-end">
                    <Nav>
                        {categoryLinks.map(category => (
                            <Link to={"/" + category.a} key={category.b} className="bg-nav-text nav-link">{category.b}</Link>
                        ))}
                        {/* Put the below links on the resources page instead of taking up header space 
                        className={currentCategory === category.b ? "bg-nav-text nav-link active" : "bg-nav-text nav-link"*/}
                        {/* <Nav.Link eventKey={"bulletin"} key={"bulletin"} className="bg-nav-text" href="https://www.in.gov/dor/files/reference/ib113.pdf" target="_blank">INDOR Bulletin #113</Nav.Link>
                        <Nav.Link eventKey={"pothole-viewer"} key={"pothole-viewer"} className="bg-nav-text" href="http://maps.indy.gov/IndyPotholeViewer" target="_blank">Indy Pothole Viewer</Nav.Link>
                        <Nav.Link eventKey={"report-pothole"} key={"report-pothole"} className="bg-nav-text" href="https://www.in.gov/indot/contact-indot/report-a-concern2/report-a-pothole/" target="_blank">Report A Pothole</Nav.Link> */}

                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
};

export default NavBar;