import React from 'react';
import { Container } from 'react-bootstrap';

const Footer = () => {
    return(
        <footer className="footer py-3">
            <Container>
                <div className="disclaimer-text"><span>Disclaimer:</span> The information contained on this website is educational in nature. Please consult your tax advisor before acting on any of the information contained herein. 
                Further, paveapothole.com is not affiliated with Indiana or any of its municpalities and
                Indiana Potholes is not an actual trademark. The trademark symbol is added in jest.
                </div>
                <div className="disclaimer-text mailto">Comments? <a href="mailto:hello@paveapothole.com">hello@paveapothole.com</a></div>
            </Container>
        </footer>
    );
};

export default Footer;